import React from 'react';
import classNames from 'classnames';

import SEO from '../components/SEO';
import Alternating5050 from '../components/Alternating5050';

const nsBase = 'page';
const ns = `${ nsBase }-contact`;

const Contact = () => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	return (
		<div className={rootClassnames}>
			<SEO title={'Contact'} />
			<Alternating5050
				heading={'Get in touch with us.'}
				altText={'Walking out'}
				mainImageMobile={'/assets/images/contact/contact1.png'}
				dataMedia
			/>
			<Alternating5050
				contactInfo={[
					{
						heading: 'Support',
						link: {
							label: '',
							url: 'https://violux.zendesk.com/hc/en-us',
							text: 'Visit Support',
						},
					},
					{
						heading: 'General Inquiries',
						link: {
							label: '',
							url: 'mailto:hello@violux.com',
							text: 'hello@violux.com',
						},
					},
				]}
				altText={'Luma product'}
				mainImageMobile={'/assets/images/contact/contact2.png'}
				dataMedia
				imgOnLeft
			/>
		</div>
	);
};

export default Contact;
