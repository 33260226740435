import React from 'react';
import classNames from 'classnames';
import Image from '../Image';
import Link from '../Link';

const ns = 'alternating-50-50';

const Alternating5050 = props => {
	const {
		eyebrow,
		heading,
		imgOnLeft = false,
		contactInfo,
	} = props;

	const rootClassnames = classNames({
		[`${ ns }`]: true,
		imgOnLeft,
	});

	return (
		<section className={`${ rootClassnames }`}>
			<div className={`${ ns }__text-container container-fluid`}>
				{
					!contactInfo
					&& (
						<div className={`${ ns }__heading-container`}>
							{
								eyebrow && <p className={`${ ns }__eyebrow`}>{ eyebrow }</p>
							}
							{
								heading && <h1 className={`${ ns }__heading`}>{ heading }</h1>
							}
						</div>
					)
				}
				{
					contactInfo
					&& (
						<div className={`${ ns }__contact-info-container`}>
							<div className={`${ ns }__contact-infos`}>
								{contactInfo.map(contact => {
									return (
										<div className={`${ ns }__contact-info`} key={contact.link.url}>
											<h5 className={`${ ns }__contact-info-heading`}>{ contact.heading }</h5>
											<p className={`${ ns }__contact-info-link-container`}>
												{/* eslint-disable-next-line */}
												<span className={`${ ns }__contact-info-text`}>{ contact.link.label } </span>
												<Link to={contact.link.url} className={`${ ns }__contact-info-link`}>{ contact.link.text }</Link>
											</p>
										</div>
									);
								})}
							</div>
						</div>
					)
				}
			</div>
			<div className={`${ ns }__image-container`}>
				<Image {...props} />
			</div>
		</section>
	);
};

export default Alternating5050;
